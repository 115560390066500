import {
  FaTh,
  FaUserAlt,
  
  FaCommentAlt,
  FaThList,
  FaPhotoVideo,FaNotEqual
} from "react-icons/fa"
import { AiOutlineUser,AiTwotoneNotification,AiFillContacts,AiOutlineLogout } from "react-icons/ai"
import {BsPenFill,BsTrashFill} from "react-icons/bs"

const menuItem = [
  {
    path: "/",
    name: "Dashboard",
    icon: <FaTh />
  },
  {
    path: "/users",
    name: "Users",
    icon: <FaUserAlt />
  },
  {
    path: "/incomplete-users",
    name: "Incomplete Users",
    icon: <AiOutlineUser />
  },
  {
    path: "/about-me-approval",
    name: "About Me Approval",
    icon: <BsPenFill />
  },
  {
    path: "/photo-approval-table",
    name: "Photo Approval",
    icon: <FaPhotoVideo />
  },
  {
    path: "/photo-id-approval",
    name: "Photo Id Approval",
    icon: <FaThList />
  },
  {
    path: "/contact-request",
    name: "Contact Request",
    icon: <AiFillContacts />
  },
  {
    path: "/delete-request",
    name: "Delete Request",
    icon: <BsTrashFill />
  },
  {
    path: "/verification-summary",
    name: "Verification Summary",
    icon: <AiFillContacts />
  },
  {
    path:"/misuse-report",
    name:"Misuse Reports",
    // icon:<FaCommentAlt/>
    icon: <FaUserAlt />
  }
]

export default menuItem
