import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const photoData = createAsyncThunk(
  "admin/photo-approval",
  async thunkAPI => {
    try {
      const data = await userService.getPhotoApprovalData()
      return { photos: data}
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const approvePhotos = createAsyncThunk(
  "admin/about-status-update",
  async (request, thunkAPI) => {
    try {
      const data = await userService.approvePhotos(request)
      return { photos: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  photos: null,
  photoApproved: false,
}

const photosSlice = createSlice({
  name: "photos",
  initialState,
  extraReducers: {
    [photoData.fulfilled]: (state, action) => {
      state.photos = action.payload.photos
    },
    [photoData.rejected]: (state, action) => {
      state.photos = null
    },
    [approvePhotos.fulfilled]: (state, action) => {
      state.photoApproved = action.payload.photos
    },
    [approvePhotos.rejected]: (state, action) => {
      state.photoApproved = null
    }
  }
})

const { reducer } = photosSlice

export default reducer
