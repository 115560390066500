import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Layout from '../../layout';
import ExportToCSV from '../../components/export';
import { DataGrid, GridToolbar, gridPageCountSelector,
    gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import Loader from '../../components/loader';
import { Button } from '@mui/material';
import Pagination from "@mui/material/Pagination"
import { deleteUser, exportCsv, usersData,membership, reportMisuseData } from "../../redux/slices/users"
import { useDispatch } from 'react-redux';
import { AiFillDelete } from 'react-icons/ai';
import { BsHandThumbsUpFill } from 'react-icons/bs';
import axios from 'axios';
import authHeader from '../../services/auth-header';
const MisuseReportsTable = () => {
    const [loading, setLoading] = useState(false)
    const [exportToCsv, setExportToCsv] = useState([])
    const [userData, setUserData] = useState([])
   
    const dispatch = useDispatch()
    function CustomPagination() {
        const apiRef = useGridApiContext()
        const page = useGridSelector(apiRef, gridPageSelector)
        const pageCount = useGridSelector(apiRef, gridPageCountSelector)
    
        return (
          <Pagination
            color='primary'
            count={pageCount}
            page={page + 1}
            onChange={(event, value) => apiRef.current.setPage(value - 1)}
          />
        )
      }

      const fetchUsersData = () => {
        dispatch(reportMisuseData())
          .unwrap()
          .then(res => {
            setUserData(res.users.data.misuseUsers);
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }

      const fetchCSVData = () => {
        dispatch(exportCsv())
          .unwrap()
          .then(res => {
            setExportToCsv(res?.users?.data?.userData)
            setLoading(false)
          })
          .catch(() => {
            setLoading(false)
          })
      }
      useEffect(() => {
        setLoading(true)
        fetchCSVData()
      }, [])
    
      useEffect(() => {
        setLoading(true)
        fetchUsersData()
      }, [])

    const columns = [
        { field: "reported_id", headerName: "Reported user", width: 130},
        { field: "reported_by_id", headerName: "Reported By", width: 300 },
        { field: "detail", headerName: "Detail", width: 130 },
        { field: "reason", headerName: "Reason", width: 100 },
        // { field: "skin", headerName: "Skin Condition", width: 150 },
        // { field: "country", headerName: "Country", width: 140 },
        {
          field: "action",
          headerName: "Action",
          width: 100,
          renderCell: params => {
            const checkSeen = async(mainId) => {
              
               axios.post("https://backend-dermacupid.onrender.com/api/v1/admin/update-misuse-status",{
                id:mainId
              },{
                headers: authHeader()
              }).then((res)=>{
                if(res.status==201){
                  toast.success("User Report Seen!")
                  fetchUsersData();
                }
              }).catch((err)=>{
                toast.error("Something went wrong!")
              })
            }
    
            return (
              <div>
                <BsHandThumbsUpFill
                  onClick={()=>{
                   
                    checkSeen(params.row._id);
                  }}
                  className='icon'
                  style={{
                    color: "green",
                    fontSize: "20px",
                    backgroundColor: "white",
                    padding: "10px"
                  }}
                />
              </div>
            )
          }
        }
      ]


  return (
    <>
    <Layout>
     <ToastContainer />
     {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
          <h1 style={{ display: "flex", justifyContent: "start" , marginBottom:'0.8rem' , marginBottom:'0.8rem' }}>Misuse Report Users</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
           {exportToCsv && exportToCsv.length &&  <Button variant='outlined'>
            <ExportToCSV data={exportToCsv} />
            </Button>}
          </div>

          <DataGrid
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row._id}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      )}

    </Layout>
    </>
  )
}

export default MisuseReportsTable