import React, { useEffect, useState } from "react"
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector
} from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import Layout from "../../layout"
import Loader from "../../components/loader"
import {
  aboutData,
  approveAboutMe,
  editAboutMe
} from "../../redux/slices/about"
import { BsHandThumbsUpFill, BsHandThumbsDownFill } from "react-icons/bs"
import ActionDialogs from "../../components/modal"
import ExportToCSV from "../../components/export"
import { Button } from "@mui/material"
import { BiEdit } from "react-icons/bi"
import AboutMeText from "../../components/modal/aboutMe"
import Pagination from "@mui/material/Pagination"

function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color='primary'
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

export default function AboutApprovalTable() {
  const dispatch = useDispatch()
  const [filterRequest, setFilterRequest] = useState({})
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [aboutModalOpen, setAboutModalOpen] = useState(false)
  const [aboutMe, setAboutMe] = useState("")
  const [uid, setUid] = useState("")
  const [userIndex, setUserIndex] = useState()
  const [modalId,setModalId]=useState();

  const columns = [
    { field: "uid", headerName: "UID", width: 350 },
    { field: "aboutme", headerName: "About Me", width: 1200 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      renderCell: params => {
        setUid(params.row.uid)
        // console.log(params,"sdfghjk");
        // console.log(userData, "userdtaa");
        const onAboutChange = e => {
          e.stopPropagation() // don't select this row after clicking
          setFilterRequest({ aboutme: aboutMe, uid: params.row.uid })
          aboutModalOpen ? setAboutModalOpen(false) : setAboutModalOpen(true)
        }
        const onEdit = e => {
          e.stopPropagation() // don't select this row after clicking

          setFilterRequest({ uid: params.row.uid, status: "" })

          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }
        const onDelete = e => {
          e.stopPropagation() // don't select this row after clicking

          setFilterRequest({
            uid: params.row.uid,
            status: "",
            show: true,
            handler: "both"
          })
          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }

        const getAboutMe = id => {
          for (let i = 0; i < userData.length; i++) {
            if (userData[i].uid === id) {
              setUserIndex(i)
              break
            }
          }
        }


        return (
          <div>
            <BiEdit
              onClick={e => {
                // console.log(params.row.uid,"my params id");
                setModalId(params.row.uid);
                getAboutMe(params.row.uid);
                onAboutChange(e)
              }}
              className='icon'
              style={{
                color: "green",
                backgroundColor: "white",
                fontSize: "20px",
                padding: "10px"
              }}
            />
            <BsHandThumbsUpFill
              onClick={()=>{
                console.log(params.row.uid);
                handleYesButton(params.row.uid);
              }}
              className='icon'
              style={{
                color: "green",
                backgroundColor: "white",
                fontSize: "20px",
                padding: "10px"
              }}
            />
            <BsHandThumbsDownFill
              onClick={()=>{
                handleNoButton(params.row.uid);
              }}
              className='icon'
              style={{
                color: "red",
                fontSize: "20px",
                backgroundColor: "white",
                padding: "10px"
              }}
            />
          </div>
        )
      }
    }
  ]

  const fetchAboutMeData = () => {
    dispatch(aboutData())
      .unwrap()
      .then(res => {
        setUserData(res?.about?.data?.users)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchAboutMeData()
  }, [])

  const handleYesButton = (u_id) => {
    dispatch(approveAboutMe({ ...filterRequest,uid:u_id, status: 1 }))
      .unwrap()
      .then(res => {
        if (res.aboutMe.status === 201) {
          fetchAboutMeData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
    setAboutModalOpen(false)
  }

  const handleNoButton = (u_id) => {
    // setFilterRequest({...filterRequest, status:"rejected"})
    dispatch(approveAboutMe({ ...filterRequest, uid:u_id, status: 2 }))
      .unwrap()
      .then(res => {
        console.log(res, "dfghjk")
        if (res.aboutMe.status === 201) {
          fetchAboutMeData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  const handleOkButton = (u_id) => {
    dispatch(editAboutMe({ ...filterRequest, uid:u_id, status: "" }))
      .unwrap()
      .then(res => {
        if (res.users.status === 201) {
          fetchAboutMeData();
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
    setAboutModalOpen(false)
  }

  // const setMyVal=async(aboutMe)=>{
  //  setFilterRequest(pre => {
  //     return {
  //       ...pre,
  //       aboutme: aboutMe
  //     }
  //   })
  // }

  useEffect(()=>{
    setFilterRequest(pre => {
      return {
        ...pre,
        aboutme: aboutMe
      }
    })
  },[aboutMe])

  const handleChange = e => {
    setAboutMe(e.target.value);
    // setMyVal(aboutMe);
    setFilterRequest(pre => {
      return {
        ...pre,
        aboutme: aboutMe
      }
    })
  }

  const requiredData = userData?.find(x => x.uid == uid)

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
          <h1
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "0.8rem"
            }}
          >
            About Me Approval
          </h1>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px"
            }}
          >
            <Button variant='outlined'>
              <ExportToCSV data={userData} />
            </Button>
          </div> */}
          <DataGrid
            getRowHeight={() => "auto"}
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row.uid}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {modalOpen && (
            <ActionDialogs
              ModalTitle={"AboutMe table"}
              modalBody={
                filterRequest?.status === 1
                  ? "Are you Sure, you want to approve?"
                  : "Are you Sure, you want to reject?"
              }
              handleYesButton={handleYesButton}
              handleNoButton={handleNoButton}
              handleChange={handleChange}
              titleShow={filterRequest?.show}
              handler={filterRequest?.handler}
            />
          )}
          {aboutModalOpen && (
            <AboutMeText
              ModalTitle={"User table"}
              handleYesButton={handleYesButton}
              AboutMeText={userData[userIndex].aboutme}
              handleChange={handleChange}
              handleOkButton={handleOkButton}
              u_id={modalId}
            />
          )}
        </div>
      )}
    </Layout>
  )
}
