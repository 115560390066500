import styled from "styled-components"

export const Container = styled.div`
  display: flex;
`

export const Wrapper = styled.div`
  background: #000;
  color: #fff;
  height: 100vh;
  width: 200px;
  transition: all 0.5s;
`

export const Bars = styled.div`
  display: flex;
  font-size: 25px;
  margin-left: 50px;
`

export const Logo = styled.div`
  font-size: 30px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 15px;
  justify-content:space-between;

`

export const MainContainer = styled.div`
  width: 100%;
  padding: 20px;
`

export const Name = styled.div`
font-size: 20px;
`

export const SvgIcons = styled.div`
font-size: 20px;
`