import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const contactData = createAsyncThunk(
  "admin/get-contact-us",
  async thunkAPI => {
    try {
      const data = await userService.getContactRequestData()
      return { contacts: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const approveContactUs = createAsyncThunk(
  "admin/update-contact-us",
  async (request, thunkAPI) => {
    try {
      const data = await userService.approveContactUs(request)
      
      return { contact: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  contacts: null,
  read:false
}

const contactSlice = createSlice({
  name: "contacts",
  initialState,
  extraReducers: {
    [contactData.fulfilled]: (state, action) => {
      state.contacts = action.payload.contacts
    },
    [contactData.rejected]: (state, action) => {
      state.contacts = null
    },
    [contactData.fulfilled]: (state, action) => {
      state.read = action.payload.contact
    },
    [contactData.rejected]: (state, action) => {
      state.read = null
    },
  }
})

const { reducer } = contactSlice

export default reducer
