import Login from "../pages/login"
import Home from "../pages/dashboard"
import UserTable from "../pages/tables/user.table"
import SubUserTable from "../pages/tables/subUser.table"
import AboutMeApprovalTable from "../pages/tables/aboutApproval.table"
import PhotoApprovalTable from "../pages/tables/photoApproval.table"
import PhotoIdApprovalTable from "../pages/tables/photoIdApproval.table"
import ContactRequest from "../pages/tables/contactRequest.table"
import DeleteRequest from "../pages/tables/deleteUser.table"
import VerificationSummaryTable from "../pages/tables/verificationSummary.table"
import MisuseReportsTable from "../pages/tables/MisuseReportsTable"

export const routes = [
  {
    id: 1,
    path: "/login",
    comp: <Login />,
    isProtected: false
  },
  {
    id: 2,
    path: "/",
    comp: <Home />,
    isProtected: true
  },
  {
    id: 3,
    path: "/users",
    comp: <UserTable />,
    isProtected: true
  },
  {
    id: 4,
    path: "/incomplete-users",
    comp: <SubUserTable />,
    isProtected: true
  },
  {
    id: 5,
    path: "/about-me-approval",
    comp: <AboutMeApprovalTable />,
    isProtected: true
  },
  {
    id: 6,
    path: "/photo-approval-table",
    comp: <PhotoApprovalTable />,
    isProtected: true
  },
  {
    id: 7,
    path: "/photo-id-approval",
    comp: <PhotoIdApprovalTable />,
    isProtected: true
  },

  {
    id: 8,
    path: "/contact-request",
    comp: <ContactRequest />,
    isProtected: true
  },
  {
    id: 9,
    path: "/delete-request",
    comp: <DeleteRequest />,
    isProtected: true
  },
  {
    id: 10,
    path: "/verification-summary",
    comp: <VerificationSummaryTable />,
    isProtected: true
  },
  {
    id: 11,
    path: "/misuse-report",
    comp: <MisuseReportsTable />,
    isProtected: true
  }
]
