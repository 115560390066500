import axios from "axios"
import authHeader from "./auth-header"
// const API_URL = "http://ec2-3-7-88-30.ap-south-1.compute.amazonaws.com:5000/api/v1/"

// const API_URL = "http://localhost:5000/api/v1/";
// const API_URL = "https://api.dermacupid.com/api/v1/";
const API_URL = "https://dev.api.dermacupid.com/api/v1/"
// const API_URL = "https://backend-dermacupid.onrender.com/api/v1/"


const getDashboardData = () => {
  return axios.get(API_URL + "admin/dashboard", { headers: authHeader() })
}

const getUsersData = () => {
  return axios.get(API_URL + "admin/users", { headers: authHeader() })
}

const getReportMisuseData = () => {
  return axios.get(API_URL + "admin/get-misuse-report-users", {
    headers: authHeader()
  })
}

const getSubUsersData = () => {
  return axios.get(API_URL + "admin/sub-users", { headers: authHeader() })
}

const getAboutMeApprovalData = () => {
  return axios.get(API_URL + "admin/about-me-approval", {
    headers: authHeader()
  })
}

const getPhotoApprovalData = () => {
  return axios.get(API_URL + "admin/photo-approval", { headers: authHeader() })
}

const getPhotoIdApprovalData = () => {
  return axios.get(API_URL + "admin/photoid-approval", {
    headers: authHeader()
  })
}

const getContactRequestData = () => {
  return axios.get(API_URL + "admin/get-contact-us", { headers: authHeader() })
}

const getDeleteRequestData = () => {
  return axios.get(API_URL + "admin/deleted-user", { headers: authHeader() })
}

// CTA BUTTONS ON TABLE

const approveAboutMe = request => {
  return axios.put(API_URL + "admin/about-status-update", request, {
    headers: authHeader()
  })
}

const deleteUser = request => {
  return axios.put(API_URL + "admin/deleted-user", request, {
    headers: authHeader()
  })
}

const updateDeleteUser = request => {
  return axios.delete(API_URL + `admin/delete/${request.id}`, {
    headers: authHeader()
  })
}

const approvePhotos = (request) => {
  return axios.put(API_URL + "admin/update-photo-status", request,{
    headers: authHeader()
  })
}

const upgradeMembership = (request) => {
  return axios.get(API_URL + `admin/paid/${request}`,{
    headers: authHeader()
  })
}

const cancelMembership = (request) => {
  return axios.get(API_URL + `admin/cancel/${request}`,{
    headers: authHeader()
  })
}

const approvePhotoId = (request) => {
  return axios.put(API_URL + "admin/update-photoid-status", request,{ headers: authHeader() })
}

const approveContactUs = request => {
  return axios.put(API_URL + "admin/update-contact-us", request, {
    headers: authHeader()
  })
}

const approveDeleteRequest = request => {
  return axios.put(API_URL + "admin/update-delete-request", request, {
    headers: authHeader()
  })
}

const approveEditRequest = request => {
  return axios.put(API_URL + "admin/edit-aboutme", request, {
    headers: authHeader()
  })
}
const CSVRequest = () => {
  return axios.get(API_URL + "admin/get-user-csv", {
    headers: authHeader()
  })
}

const userService = {
  getUsersData,
  getSubUsersData,
  getAboutMeApprovalData,
  getPhotoApprovalData,
  getPhotoIdApprovalData,
  getContactRequestData,
  getDeleteRequestData,
  getDashboardData,
  CSVRequest,

  // CTA
  approveAboutMe,
  deleteUser,
  updateDeleteUser,
  approvePhotos,
  approvePhotoId,
  approveContactUs,
  approveDeleteRequest,
  approveEditRequest,
  upgradeMembership,
  cancelMembership,
  getReportMisuseData
}

export default userService
