import React, { useEffect, useState } from "react"
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector
} from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import Layout from "../../layout"
import { approvePhotos, photoData } from "../../redux/slices/photo"
import Loader from "../../components/loader"
import ActionDialogs from "../../components/modal"
import {
  BsCardImage,
  BsHandThumbsDownFill,
  BsHandThumbsUpFill
} from "react-icons/bs"
import { Button } from "@mui/material"
import ExportToCSV from "../../components/export"
import Pagination from "@mui/material/Pagination"

function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color='primary'
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

export default function PhotoApprovalTable() {
  const [modalOpen, setModalOpen] = useState(false)
  const [filterRequest, setFilterRequest] = useState({})
  const dispatch = useDispatch()
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [indexImage, setIndexImage] = useState(0);
  const [userId,setUserId]=useState();

  

  const columns = [
    { field: "uid", headerName: "UID", width: 350 },
    {
      field: "Photos",
      headerName: "Photos",
      flex: 0,
      width: 1200,
      renderCell: params => {
        const onPhoto = index => {
          console.log(index,"this is my index");
          setIndexImage(index);
          // setUserId(params.row.photos[indexImage]._id);
          setFilterRequest({
            _id: params?.row._id,
            photo_id:params?.row.photos[indexImage]?._id,
            status: "",
            show: false,
            handler: "both"
          })
          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }
        // console.log(params);
        const getMyUser=(id)=>{
          // console.log(id,"myId");
          for(let i=0;i<userData.length;i++){
            let flag=0;
            for(let j=0;j<userData[i].photos.length;j++){
              if(userData[i].photos[j]._id===id){
                setUserId(i);
                flag=1;
                 break;
              }
            }
            if(flag===1)
            break;
           }
          }
          /* if(userData[i]._id===id){
              console.log(userData[i]._id);
               break;
            } */
        return (
          <>
            {params.row.photos.map((item, index) => (
              <BsCardImage
                style={{ fontSize: "20px", color: "green", marginLeft: "10px" }}
                onClick={() => {
                  console.log(item._id,"zsdfghjk")
                  getMyUser(item._id);
                  return onPhoto(index)
                }}
              >
                image-{index + 1}
              </BsCardImage>
            ))}
          </>
        )
      }
    }
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 200,
    //   sortable: false,
    //   renderCell: params => {
    //     const onEdit = e => {
    //       e.stopPropagation()
    //       setFilterRequest({ _id: params.row._id, status: "approved",show:true,handler:"approve" })
    //       modalOpen ? setModalOpen(false) : setModalOpen(true)
    //     }
    //     const onDelete = e => {
    //       e.stopPropagation()
    //       setFilterRequest({ _id: params.row._id, status: "rejected",show:true,handler:"reject" })
    //       modalOpen ? setModalOpen(false) : setModalOpen(true)
    //     }

    //     return (
    //       <div>
    //         <BsHandThumbsUpFill
    //           onClick={onEdit}
    //           className='icon'
    //           style={{
    //             color: "green",
    //             fontSize: "20px",
    //             backgroundColor: "white",
    //             padding: "10px",
    //             marginRight: "20px"
    //           }}
    //         />
    //         <BsHandThumbsDownFill
    //           onClick={onDelete}
    //           className='icon'
    //           style={{
    //             color: "red",
    //             fontSize: "20px",
    //             backgroundColor: "white",
    //             padding: "10px"
    //           }}
    //         />
    //       </div>
    //     )
    //   }
    // }
  ]

  const fetchPhotosData = () => {
    dispatch(photoData())
      .unwrap()
      .then(res => {
        res?.photos?.data?.users?.map((item,index)=>{
          let arr=[];
          item.photos.map((val,valIndex)=>{
             if(val.photoApproved===0)
              arr.push(val);
          })
          item.photos=arr;
        })
        console.log(res.photos.data.users,"here is my required data");
        setUserData(res.photos.data.users);
        setLoading(false)
      })
      /*  res?.photos?.data?.users?.map(item => item?.photos?.forEach(item => {
          console.log(item,"iteem");
          // if(item.photoApproved===0){
          //   arr.push(item);
          // }
         
          
          // if(item?.photoApproved === 0){
          //   setUserData(res.photos.data.users)
          // }
        }
        ))
        // res.photos.data.users.photos=arr;
        // console.log(res.photos.data.users.photos,"my settled array");
        // setUserData(res.photos.data.users); */
      .catch(() => {
        setLoading(false)
      })
  }


  useEffect(() => {
    setLoading(true)
    fetchPhotosData()
  }, [dispatch])

  const handleNoButton = () => {
    // setFilterRequest({...filterRequest, status:"rejected"})
    dispatch(approvePhotos({ ...filterRequest, status: "rejected" }))
      .unwrap()
      .then(res => {
        if (res.photos.status === 201) {
          fetchPhotosData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  const handleYesButton = () => {
    // setFilterRequest({...filterRequest, status:"approved"})
    dispatch(approvePhotos({ ...filterRequest, status: "approved" }))
      .unwrap()
      .then(res => {
        if (res.photos.status === 201) {
          fetchPhotosData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
          <h1
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "0.8rem"
            }}
          >
            Photo Approval
          </h1>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px"
            }}
          >
            <Button variant='outlined'>
              <ExportToCSV data={userData} />
            </Button>
          </div> */}
          <DataGrid
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row._id}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
           {modalOpen && (
            <ActionDialogs
              ModalTitle={"Photo Approval table"}
              modalBody={
                filterRequest?.status === "approved"
                  ? "Are you Sure, you want to approve?"
                  : "Are you Sure, you want to reject?"
              }
              handleYesButton={handleYesButton}
              handleNoButton={handleNoButton}
              titleShow={filterRequest?.show}
              modalImage={userData && userData[userId]?.photos[indexImage]?.photo}
              handler={filterRequest?.handler}
            />
          )}
        </div>
      )}
    </Layout>
  )
}
