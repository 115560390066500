import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography
} from "@mui/material"
import { FaTimes } from "react-icons/fa"
import { Box } from "@mui/system"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2)
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1)
  }
}))

const BootstrapDialogTitle = props => {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: theme => theme.palette.grey[500]
          }}
        >
          <FaTimes />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
}

export default function ActionDialogs({
  ModalTitle,
  modalBody,
  handleYesButton,
  handleNoButton,
  titleShow,
  modalImage,
  handler,
  onClose
}) {
  const [open, setOpen] = useState(true)
  // console.log(modalImage,"f");

  const handleClose = () => {
    setOpen(false)
    onClose && onClose();
  }

  // console.log(modalBody, "modalBody")

  return (
    <BootstrapDialog
    
      onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
    >
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        {ModalTitle}
      </BootstrapDialogTitle>
      {
        titleShow ? <DialogContent dividers>
        <Typography gutterBottom>{modalBody}
        </Typography>
      </DialogContent> : null
      }
      
      <DialogContent dividers>
        {modalImage && (
          <Box
            component='img'
            sx={{
              // height: 600,
              // width: 800,     
              objectFit: "contains"
            }}
            alt='The house from the offer.'
            src={modalImage}
          />
        )}
      </DialogContent>
      <DialogActions>
      {
        handler ==="approve" ?
        <>
        <Button autoFocus onClick={handleYesButton}>
          Approved
        </Button>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button> 
        </>
        : null
      }
        
        {
          handler ==="reject" ? <>
          <Button autoFocus onClick={handleYesButton}>
          Reject
        </Button>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button> 
          </>
        : 
        null
        }

        {
          handler ==="both" ? <>
          <Button autoFocus onClick={handleYesButton}>
          Approved
        </Button>
        <Button autoFocus onClick={handleNoButton || handleClose}>
          Reject
        </Button>
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button> 
          </> : null
        }
        
      </DialogActions>
    </BootstrapDialog>
  )
}
