import React, { useEffect, useState } from "react"
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector
} from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import { BsHandThumbsUpFill } from "react-icons/bs"
import Layout from "../../layout"
import PropTypes from "prop-types"
import Button from "@mui/material/Button"
import { styled } from "@mui/material/styles"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography
} from "@mui/material"
import { FaTimes } from "react-icons/fa"
import ExportToCSV from "../../components/export"
import { approveContactUs, contactData } from "../../redux/slices/contact"
import Loader from "../../components/loader"
import moment from "moment"
import Pagination from "@mui/material/Pagination"

function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color='primary'
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

export default function ContactRequestTable() {
  const [modalOpen, setModalOpen] = useState(false)
  const [filterRequest, setFilterRequest] = useState({})
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1)
    }
  }))

  const BootstrapDialogTitle = props => {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label='close'
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme => theme.palette.grey[500]
            }}
          >
            <FaTimes />
          </IconButton>
        ) : null}
      </DialogTitle>
    )
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired
  }

  function ActionDialogs({ ModalTitle, modalBody }) {
    const [open, setOpen] = useState(true)

    const handleClose = () => {
      setOpen(false)
    }

    return (
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={open}
      >
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}
        >
          {ModalTitle}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>{modalBody}</Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleYesButton}>
            Yes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    )
  }

  const columns = [
    { field: "_id", headerName: "ID", width: 300 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "subject", headerName: "Subject", width: 200 },
    { field: "description", headerName: "Description", width: 500 },
    {
      field: "date",
      headerName: "Date",
      flex: 0,
      minWidth: 200,
      renderCell: params => {
        // return moment(params.value).format("DD-MM-YYYY")
        return moment(params.row.createdAt).format("DD-MM-YYYY")
      }
    },
    // {
    //   field: "Photo",
    //   headerName: "photo",
    //   minWidth: 200,
    //   renderCell: params => {
    //     console.log(params.row.photo,"log")
    //     return params.row.photo
    //   }
    // },
    {
      field: "Photo",
      headerName: "photo",
      minWidth: 200,
      renderCell: params => {
        if (params.row.photo) {
          return (
            <a href={params.row.photo} target="_blank" rel="noopener noreferrer">
              Click to download
            </a>
          );
        } else {
          return "No photo";
        }
      }
    },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      sortable: false,
      renderCell: params => {
        const onEdit = e => {
          e.stopPropagation() // don't select this row after clicking
          setFilterRequest({ _id: params.row._id, isRead: true })
          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }

        return (
          <div>
            <BsHandThumbsUpFill
              onClick={onEdit}
              className='icon'
              style={{
                color: "green",
                backgroundColor: "white",
                fontSize: "20px",
                padding: "10px"
              }}
            />
          </div>
        )
      }
    }
  ]

  const dispatch = useDispatch()

  const fetchContactData = () => {
    dispatch(contactData())
      .unwrap()
      .then(res => {
        const data = res.contacts.data.contact.filter(item => item.isRead === false)
        
        setUserData(data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchContactData()
  }, [])

  const handleYesButton = () => {
    dispatch(approveContactUs(filterRequest))
      .unwrap()
      .then(res => {
        if (res.contact.status === 201) {
          fetchContactData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <h1
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "0.8rem"
            }}
          >
            Contact Request
          </h1>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px"
            }}
          >
            <Button variant='outlined'>
              <ExportToCSV data={userData} />
            </Button>
          </div> */}
          <DataGrid
           getRowHeight={() => "auto"}
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row._id}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,

                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {modalOpen && (
            <ActionDialogs
              ModalTitle={"Contact Request table"}
              modalBody={
                filterRequest?.isRead === true
                  ? "Are you Sure, you want to approve?"
                  : "Are you Sure, you want to reject?"
              }
              handleYesButton={handleYesButton}
              handler={filterRequest?.handler}
            />
          )}
        </div>
      )}
    </Layout>
  )
}
