import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const subUsersData = createAsyncThunk(
  "admin/sub-users",
  async thunkAPI => {
    try {
      const data = await userService.getSubUsersData()
      return { subUsers: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const deleteUser = createAsyncThunk(
  "admin/update-delete-request",
  async (request, thunkAPI) => {
    try {
      const data = await userService.deleteUser(request)
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  subUsers: null
}

const subUserSlice = createSlice({
  name: "subUsers",
  initialState,
  extraReducers: {
    [subUsersData.fulfilled]: (state, action) => {
      state.subUsers = action.payload.subUsers
    },
    [subUsersData.rejected]: (state, action) => {
      state.subUsers = null
    }
  }
})

const { reducer } = subUserSlice

export default reducer
