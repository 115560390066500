import axios from "axios";
// const API_URL = "http://ec2-3-7-88-30.ap-south-1.compute.amazonaws.com:5000/api/v1/";
// const API_URL = "https://backend-dermacupid.onrender.com/api/v1/";
const API_URL = "https://dev.api.dermacupid.com/api/v1/"
// const API_URL = "http://localhost:5000/api/v1/";
// const API_URL = "https://api.dermacupid.com/api/v1/";

const login = (email, password) => {
  return axios
    .post(API_URL + "login/admin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("user");
};

const authService = {
  login,
  logout,
};

export default authService;
