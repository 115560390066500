import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const usersData = createAsyncThunk(
  "admin/users",
  async thunkAPI => {
    try {
      const data = await userService.getUsersData()
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const reportMisuseData = createAsyncThunk(
  "admin/get-misuse-report-users",
  async thunkAPI => {
    try {
      const data = await userService.getReportMisuseData()
      return { users: data }
    } catch (error) {
      const message =
      (error.response &&
        error.response.data &&
        error.response.data.message) ||
      error.message ||
      error.toString()
    thunkAPI.dispatch(setMessage(message))
    return thunkAPI.rejectWithValue()
    }
  }
)

export const membership = createAsyncThunk(
  "admin/paid",
  async (request, thunkAPI) => {
    
    try {
      const data = await userService.upgradeMembership(request)
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)


export const cancelMembership = createAsyncThunk(
  "admin/cancel",
  async (request, thunkAPI) => {
    
    try {
      const data = await userService.cancelMembership(request)
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const exportCsv = createAsyncThunk(
  "admin/get-user-csv",
  async thunkAPI => {
    try {
      const data = await userService.CSVRequest()
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)
export const deleteUser = createAsyncThunk(
  "admin/update-delete-request",
  async (request, thunkAPI) => {
    try {
      const data = await userService.updateDeleteUser(request)
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  users: null,
  userDeleted: false,
  csvData:null
}

const userSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [usersData.fulfilled]: (state, action) => {
      state.users = action.payload.dashboard
    },
    [usersData.rejected]: (state, action) => {
      state.users = null
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.userDeleted = action.payload.users
    },
    [deleteUser.rejected]: (state, action) => {
      state.userDeleted = false
    },
    [exportCsv.fulfilled]: (state, action) => {
      state.csvData = action.payload.users
    },
    [exportCsv.rejected]: (state, action) => {
      state.csvData = null
    }
  }
})

const { reducer } = userSlice

export default reducer
