import React from "react"
import { Route, Routes } from "react-router-dom";
import { logout } from "./redux/slices/auth";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { routes } from "./routes/routes";
import Protection from "./routes/protected.routes"

export default function App() {
  const dispatch = useDispatch();

  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Routes>
     {routes.map(route => (
        <Route
          key={route.id}
          path={route.path}
          element={
            route.isProtected ? (
              <Protection>
               {route.comp}
              </Protection>
            ) : (
              route.comp
            )
          }
        />
      ))}
    </Routes>
  )
}
