import React, { useEffect, useState } from "react"
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector
} from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import Layout from "../../layout"
import Loader from "../../components/loader"
import ActionDialogs from "../../components/modal"
import {
  BsCardImage,
  BsHandThumbsDownFill,
  BsHandThumbsUpFill
} from "react-icons/bs"
import { approvePhotoId, photoIdData } from "../../redux/slices/photoId"
import ExportToCSV from "../../components/export"
import { Button } from "@mui/material"
import Pagination from "@mui/material/Pagination"

function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color='primary'
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

export default function PhotoIdApprovalTable() {
  const [modalOpen, setModalOpen] = useState(false)
  const [filterRequest, setFilterRequest] = useState({})
  const dispatch = useDispatch()
  const [userData, setUserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [indexImage, setIndexImage] = useState()

  const columns = [
    { field: "uid", headerName: "UID", width: 350 },
    {
      field: "photoId",
      headerName: "photoId",
      flex: 0,
      width: 1200,
      renderCell: params => {
        const onPhoto = e => {
          setFilterRequest({
            _id: params.row._id,
            status: "",
            show: false,
            handler: "both"
          })
          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }
        const getMyUser=(id)=>{
          for(let i=0;i<userData.length;i++){
            if(userData[i]._id===id)
            {
              setIndexImage(i);
              break;
            }
          }
        }
        return (
          <BsCardImage
            style={{ fontSize: "20px", color: "green" }}
            onClick={()=>{
              getMyUser(params.row._id);
              onPhoto();
            }}
          >
            See the image
          </BsCardImage>
        )
      }
    }
  ]

  const fetchPhotoIdData = () => {
    dispatch(photoIdData())
      .unwrap()
      .then(res => {
        res?.photoId?.data?.users?.forEach(item => {
          if (item.photoIDApproved == 0) {
            setUserData(res.photoId.data.users)
          }
        })
        // console.log(res.photoId.data.users);
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchPhotoIdData()
  }, [dispatch])

  const handleYesButton = () => {
    dispatch(approvePhotoId({ ...filterRequest, status: "approved" }))
      .unwrap()
      .then(res => {
        if (res.photoId.status === 201) {
          fetchPhotoIdData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  const handleNoButton = () => {
    dispatch(approvePhotoId({ ...filterRequest, status: "reject" }))
      .unwrap()
      .then(res => {
        if (res.photoId.status === 200) {
          // alert('PhotoId Rejected');
          console.log("Hola",  res.photoId.status)
          fetchPhotoIdData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
          <h1
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "0.8rem"
            }}
          >
            Photo ID Approval
          </h1>
          <DataGrid
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row._id}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 }
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {modalOpen && (
            <ActionDialogs
              ModalTitle={"Photo Id table"}
              modalBody={
                filterRequest?.status === "approved"
                  ? "Are you Sure, you want to approve?"
                  : "Are you Sure, you want to reject?"
              }
              handleYesButton={handleYesButton}
              handleNoButton={handleNoButton}
              modalImage={userData?.length && userData[indexImage]?.photoID}
              titleShow={filterRequest?.show}
              handler={filterRequest?.handler}
              // modalImage={userData && userData[0]?.photos[indexImage].photo}
            />
          )}
        </div>
      )}
    </Layout>
  )
}
