import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const photoIdData = createAsyncThunk(
  "admin/photoid-approval",
  async thunkAPI => {
    try {
      const data = await userService.getPhotoIdApprovalData()
      return { photoId: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const approvePhotoId = createAsyncThunk(
  "admin/update-photoid-status",
  async (request, thunkAPI) => {
    try {
      const data = await userService.approvePhotoId(request)
      return { photoId: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  photoId: null,
  photoIdApproved:false,
}

const photoIdSlice = createSlice({
  name: "photoId",
  initialState,
  extraReducers: {
    [photoIdData.fulfilled]: (state, action) => {
      state.photoId = action.payload
    },
    [photoIdData.rejected]: (state, action) => {
      state.photoId = null
    },
    [approvePhotoId.fulfilled]: (state, action) => {
      state.photoIdApproved = action.payload.photoId
    },
    [approvePhotoId.rejected]: (state, action) => {
      state.photoIdApproved = null
    }
  }
})

const { reducer } = photoIdSlice

export default reducer
