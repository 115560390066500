import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const aboutData = createAsyncThunk(
  "admin/about-me-approval",
  async thunkAPI => {
    try {
      const data = await userService.getAboutMeApprovalData()
      return { about: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const  editAboutMe= createAsyncThunk(
  "admin/edit-aboutme",
  async (request, thunkAPI) => {
  
    try {
      const data = await userService.approveEditRequest(request)
      
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const approveAboutMe = createAsyncThunk(
  "admin/about-status-update",
  async (request, thunkAPI) => {
    try {
      const data = await userService.approveAboutMe(request)
      return { aboutMe: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  about: null,
  aboutMeApproval:false,
  editAboutMe:null,
}

const aboutSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [aboutData.fulfilled]: (state, action) => {
      state.about = action.payload.about
    },
    [aboutData.rejected]: (state, action) => {
      state.about = null
    },
    [approveAboutMe.fulfilled]: (state, action) => {
      state.aboutMeApproval = true
    },
    [approveAboutMe.rejected]: (state, action) => {
      state.aboutMeApproval = null
    },
    [editAboutMe.fulfilled]: (state, action) => {
      state.editAboutMe = action.payload.aboutMe
    },
    [editAboutMe.rejected]: (state, action) => {
      state.editAboutMe = null
    }
  }
})

const { reducer } = aboutSlice

export default reducer
