import React, { useEffect, useState }  from "react"
import Card from "../../components/widgets"
import Layout from "../../layout"
import { dashboardData } from "../../redux/slices/dashboard"
import { useDispatch } from "react-redux"

export default function Dashboard() {
  const [dataForDashboard, setDataForDashboard] = useState(null)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const fetchDashBoardData = () => {
    dispatch(dashboardData())
      .unwrap()
      .then(res => {
        return setDataForDashboard(res.dashboard.data)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchDashBoardData()
  }, [])
  return (
    <Layout>
      <Card type='user' dataForDashboard={dataForDashboard}/>
      <Card type='subUser' dataForDashboard={dataForDashboard}/>
      <Card type='about' dataForDashboard={dataForDashboard}/>
      <Card type='photo' dataForDashboard={dataForDashboard}/>
      <Card type='photoId' dataForDashboard={dataForDashboard}/>
      <Card type='contact' dataForDashboard={dataForDashboard}/>
      <Card type='delete' dataForDashboard={dataForDashboard}/>
      <Card type='verification' dataForDashboard={dataForDashboard}/>
    </Layout>
  )
}
