import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import Layout from "../../layout";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import ActionDialogs from "../../components/modal";
import { BsHandThumbsUpFill, BsHandThumbsDownFill } from "react-icons/bs";
import ExportToCSV from "../../components/export";
import { Button,DialogActions } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import {
  approveDeleteRequest,
  deletedUserData,
} from "../../redux/slices/deletedUser";
import Loader from "../../components/loader";
import moment from "moment";
import Pagination from "@mui/material/Pagination";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

export default function DeleteUserTable() {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterRequest, setFilterRequest] = useState({});
  const dispatch = useDispatch();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);

  const columns = [
    { field: "uid", headerName: "UID", width: 300 },
    { field: "deleteReason", headerName: "Reason", width: 500 },
    { field: "deleteDetails", headerName: "More Details", width: 600 },
    {
      field: "date",
      headerName: "Date",
      flex: 0,
      minWidth: 200,
      renderCell: (params) => {
        return moment.utc(params.row.deletedAt).format("DD-MM-YYYY");
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 400,
      sortable: false,
      renderCell: (params) => {
        const onEdit = (e) => {
          
          e.stopPropagation(); // don't select this row after clicking
          setFilterRequest({
            uid: params?.row?.uid,
            status: "accepted",
            handler: "both",
          });
          modalOpen ? setModalOpen(false) : setModalOpen(true);
        };
        const onDelete = (e) => {
         
          e.stopPropagation(); // don't select this row after clicking
          setFilterRequest({
            uid: params?.row?.uid,
            status: "rejected",
            handler: "both",
          });
          modalOpen ? setModalOpen(false) : setModalOpen(true);
        };

        return (
          <div>
            <BsHandThumbsUpFill
              onClick={() => {
                handleYesButton(params?.row?.uid);
              }}
              className="icon"
              style={{
                color: "green",
                backgroundColor: "white",
                fontSize: "20px",
                padding: "10px",
              }}
            />
            <BsHandThumbsDownFill
              onClick={() => {
                handleNoButton(params.row.uid);
              }}
              className="icon"
              style={{
                color: "red",
                fontSize: "20px",
                backgroundColor: "white",
                padding: "10px",
              }}
            />
          </div>
        );
      },
    },
  ];

  const fetchUsersData = () => {
    dispatch(deletedUserData())
      .unwrap()
      .then((res) => {
        setUserData(res.users.data.users);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchUsersData();
  }, []);

  const handleYesButton = (u_id) => {
   
    setFilterRequest({ ...filterRequest, status: "accepted",handler:"approve",uid:u_id });
    setModalOpen(true);
    // dispatch(approveDeleteRequest({ ...filterRequest, uid: u_id, status: "accepted" }))
    //   .unwrap()
    //   .then((res) => {
    //     console.log(res.users.status, "hola");
    //     if (res.users.status === 201) {
    //       alert('User Accepted');
    //       fetchUsersData();
    //     }
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
    // setModalOpen(false);
  };


  const handleInnerYesButton = (u_id) => {
   
    if(filterRequest.status === "accepted"){
      
      dispatch(approveDeleteRequest({ ...filterRequest, uid: filterRequest.uid, status: "accepted" }))
        .unwrap()
        .then((res) => {
          
          if (res.user.status === 201) {
            toast.success("User Deleted!")
            // alert('User Accepted');
            
            fetchUsersData();
            
          }
          setLoading(false);
        })
        .catch((err) => {
         
          setLoading(false);
        });
      setModalOpen(false);
    }else{
     
      dispatch(approveDeleteRequest({ ...filterRequest, uid: filterRequest.uid, status: "rejected" }))
        .unwrap()
        .then((res) => {
          if (res.user.status === 201) {
            // alert('User Restored');
            toast.success("User Restored!")
            fetchUsersData();
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        })
      setModalOpen(false);
    }
  }  
  
  const handleNoButton = (u_id) => {
    
    setFilterRequest({ ...filterRequest, status: "rejected",handler:"approve",uid:u_id });
    setModalOpen(true);
    // setFilterRequest({...filterRequest, status:"rejected"})
    // dispatch(approveDeleteRequest({ ...filterRequest, uid: u_id, status: "rejected" }))
    //   .unwrap()
    //   .then((res) => {
    //     if (res.users.status === 200) {
    //       alert('User Rejected');
    //       fetchUsersData();
    //     }
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     setLoading(false);
    //   });
    // setModalOpen(false);
  };

  return (
    <Layout>
     <ToastContainer />
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <h1
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "0.8rem",
            }}
          >
            Delete User
          </h1>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px"
            }}
          >
            <Button variant='outlined'>
              <ExportToCSV data={userData} />
            </Button>
          </div> */}
          <DataGrid
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowId={(row) => row._id}
            components={{ Toolbar: GridToolbar, Pagination: CustomPagination }}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {modalOpen && (
            <ActionDialogs
              modalBody={
                filterRequest?.status === "accepted"
                  ? "Confirm Deletion?"
                  : "Confirm Restoration?"
              }
              handleYesButton={handleInnerYesButton}
              handler={filterRequest?.handler}
              titleShow={true}
              onClose={() => setModalOpen(false)}
            >
            </ActionDialogs>
          )}
        </div>
      )}
    </Layout>
  );
}
