import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector,
} from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import Layout from "../../layout";
import ExportToCSV from "../../components/export";
import { Button } from "@mui/material";
import Loader from "../../components/loader";
import { exportCsv, usersData } from "../../redux/slices/users";
import Pagination from "@mui/material/Pagination";

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

const columns = [
  { field: "uid", headerName: "UID", width: 500 },
  { field: "gender", headerName: "Gender", width: 200 },
  // { field: "trustScore", headerName: "Trust Score", width: 200 },
  // {
  //   field: "trustScore",
  //   headerName: "Trust Score",
  //   // flex: 0,
  //   width: 200,
  //   renderCell: (params) => {
  //     console.log(params, "dfsjhsjk");
  //     return params.row.mobileVerified &&
  //       params.row.emailVerified &&
  //       params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1) &&
  //       params.row.photoIDApproved===1
  //       ? "100%"
  //       : params.row.mobileVerified &&
  //         params.row.emailVerified &&
  //         (params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1) || params.row.photoIDApproved===1)
  //       ? "70%"
  //       : (params.row.mobileVerified || params.row.emailVerified) &&
  //         params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1) &&
  //         params.row.photoIDApproved===1
  //       ? "80%"
  //       : params.row.mobileVerified && params.row.emailVerified
  //       ? "40%"
  //       : (params.row.mobileVerified || params.row.emailVerified) &&
  //         (params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1) || params.row.photoIDApproved===1)
  //       ? "50%"
  //       : params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1) && params.row.photoIDApproved===1
  //       ? "60%" : (params.row.mobileVerified || params.row.emailVerified) ? "20%" : "0%" 
  //   },

  //   //    ((params.row.mobileVerified &&
  //   // params.row.emailVerified) &&
  //   // (params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1) &&
  //   // params.row.photoIDApproved===1))
  //   // ? "100%": (params.row.mobile ||
  //   //     params.row.email ||
  //   //     params.row.photos.length > 0 ||
  //   //     params.row.photoId) &&
  //   //   (params.row.email ||
  //   //     params.row.photos.length > 0 ||
  //   //     params.row.photoId) &&
  //   //   (params.row.photos.length > 0 || params.row.photoId) || (params.row.photoId )
  //   // ? "75%"
  //   // : (params.row.mobile ||
  //   //     params.row.email ||
  //   //     params.row.photos.length > 0 ||
  //   //     params.row.photoId) &&
  //   //   (params.row.email ||
  //   //     params.row.photos.length > 0 ||
  //   //     params.row.photoId)
  //   // ? "50%"
  //   // : params.row.mobile ||
  //   //   params.row.email ||
  //   //   params.row.photos.length > 0 ||
  //   //   params.row.photoId
  //   // ? "25%"
  //   // : null
  // },
  {
    field: "mobileVerified",
    headerName: "Mobile",
    flex: 0,
    width: 200,
    renderCell: (params) => {
      return params?.row?.mobileVerified ? "yes" : "no";
    },
  },
  {
    field: "emailVerified",
    headerName: "Email",
    width: 200,
    renderCell: (params) => {
      return params?.row?.emailVerified ? "yes" : "no";
    },
  },
  // { field: "photo", headerName: "photo", width: 400 },
  {
    field: "photo",
    headerName: "Photo",
    // flex: 0,
    width: 200,
    renderCell: (params) => {
      return params?.row?.photos.length && params?.row?.photos.find(item => item.photoApproved === 1)  ? "yes" : "no";
    },
  },
  // { field: "photoId", headerName: "photoID", width: 400 }
  {
    field: "photoIDApproved",
    headerName: "Selfie Verification",
    // flex: 0,
    width: 200,
    renderCell: (params) => {
      return params.row.photoIDApproved===1 ? "yes" : "no";
    },
  },
];

export default function VerificationSummaryTable() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  // const [exportToCsv, setExportToCsv] = useState([])

  const fetchUsersData = () => {
    dispatch(usersData())
      .unwrap()
      .then((res) => {
        setUserData(res.users.data.users);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    fetchUsersData();
  }, []);

  // const fetchCSVData = () => {
  //   dispatch(exportCsv())
  //     .unwrap()
  //     .then(res => {
  //       setExportToCsv(res?.users?.data?.userData)
  //       setLoading(false)
  //     })
  //     .catch(() => {
  //       setLoading(false)
  //     })
  // }
  // useEffect(() => {
  //   setLoading(true)
  //   fetchCSVData()
  // }, [])

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
          <h1
            style={{
              display: "flex",
              justifyContent: "start",
              marginBottom: "0.8rem",
            }}
          >
            Verification Summary
          </h1>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1rem"
            }}
          >
            <Button variant='outlined'>
              <ExportToCSV data={exportToCsv} />
            </Button>
          </div> */}
          {/* {console.log(userData, "userData")} */}
          <DataGrid
            pagination
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            components={{
              Toolbar: GridToolbar,
              Pagination: CustomPagination,
            }}
            getRowId={(row) => row._id}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                quickFilterProps: { debounceMs: 500 },
                csvOptions: { disableToolbarButton: true },
              },
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
        </div>
      )}
    </Layout>
  );
}
