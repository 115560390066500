import styled from "styled-components"

export const Widget = styled.div`
  display: -webkit-inline-box;
  flex-wrap: wrap;
  display: inline-table;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const CardsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const CardItems = styled.div`
  min-width: 259px;
  border: #7c7c7c solid 1px;
  width: 20%;
  padding: 10px;
  margin: 10px;
  height: 150px;
  background-color: rgb(0, 0, 0);
`
export const CardInner = styled.div`
  background: #fff;
`

export const Section = styled.div`
  margin: 0px;
`

export const PercentageIncrease = styled.div`
  font-size: 14px;
`

export const CardBottom = styled.div`
  background: black;
  height: 100%;
  padding-top: 70px;
`

export const CardBottomDetails = styled.div`
  border-top: 1px solid rgba(236, 4, 4, 0.3);
  font-size: 8px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  color: white;
`
export const CardTop = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0;
  background-color: rgb(0, 0, 0);
`
export const Title = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: white;
`

export const Counter = styled.div`
  font-size: 28px;
  font-weight: 300;
  color: white;
`

export const Links = styled.span`
  width: max-content;
  font-size: 14px;
  border-bottom: 1px solid gray;
  color: white;
`

export const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Percentage = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-right: 10px;
  color: white;
`
