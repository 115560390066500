import React, { useState } from "react"
import { AiOutlineLogout } from "react-icons/ai"
import { FaBars } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { NavLink } from "react-router-dom"
import menuItem from "../../dummyData"
import { logout } from "../../redux/slices/auth"
import {
  Bars,
  Container,
  Logo,
  MainContainer,
  Name,
  SvgIcons,
  Top,
  Wrapper
} from "./sidebar.styles"

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const dispatch = useDispatch()

  const handleLogout = () => {
    dispatch(logout())
  }

  return (
    <Container>
      <Wrapper style={{ width: isOpen ? "350px" : "50px" , paddingTop:'25px' }}>
        <Top>
          <Logo style={{ display: isOpen ? "block" : "none" }}>
            Derma Cupid
          </Logo>
          <Bars style={{ marginLeft: isOpen ? "50px" : "0px" }}>
            <FaBars onClick={toggle} />
          </Bars>
        </Top>
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className='link'
            activeclassname='active'
          >
            <SvgIcons>{item.icon}</SvgIcons>
            <Name style={{ display: isOpen ? "block" : "none" }}>
              {item.name}
            </Name>
          </NavLink>
        ))}
        <NavLink to={"/login"} className='link' activeclassname='active' onClick={handleLogout}>
          <SvgIcons>
            <AiOutlineLogout />
          </SvgIcons>
          <Name style={{ display: isOpen ? "block" : "none" }}>Logout</Name>
        </NavLink>
      </Wrapper>
      <MainContainer>{children}</MainContainer>
    </Container>
  )
}

export default Sidebar
