import React from "react"
import "./style.css"

export default function Loader() {
  return (
    <div className='main'>
      <div className='square'></div>
    </div>
  )
}
