import React, { useEffect, useState } from "react"
import {
  DataGrid,
  GridToolbar,
  useGridApiContext,
  useGridSelector,
  gridPageCountSelector,
  gridPageSelector
} from "@mui/x-data-grid"
import { useDispatch } from "react-redux"
import Layout from "../../layout"
import Loader from "../../components/loader"
import { subUsersData } from "../../redux/slices/subUsers"
import { AiFillDelete, AiFillEdit } from "react-icons/ai"
import ActionDialogs from "../../components/modal"
import { deleteUser } from "../../redux/slices/users"
import ExportToCSV from "../../components/export"
import { Button } from '@mui/material';
import moment from "moment"
import Pagination from "@mui/material/Pagination"

function CustomPagination() {
  const apiRef = useGridApiContext()
  const page = useGridSelector(apiRef, gridPageSelector)
  const pageCount = useGridSelector(apiRef, gridPageCountSelector)

  return (
    <Pagination
      color='primary'
      count={pageCount}
      page={page + 1}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  )
}

export default function SubUserTable() {
  const dispatch = useDispatch()
  const [userData, setUserData] = useState([])
  const [filterRequest, setFilterRequest] = useState({})
  const [loading, setLoading] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "uid", headerName: "UID", width: 300 },
    { field: "Contact", headerName: "Contact Number", width: 150 },
    { field: "gender", headerName: "Gender", width: 100 },
    {
      field: "dob",
      headerName: "DOB",
      flex: 0,
      width: 100,
      renderCell: params => {
        return moment(params.value).format("DD-MM-YYYY")
      }
    },
    { field: "skinCondition", headerName: "Skin Condition", width: 200 },
    { field: "country", headerName: "Country", width: 130 },
    { field: "Email", headerName: "Email", width: 500 },
    {
      field: "action",
      headerName: "Action",
      width: 60,
      sortable: false,
      renderCell: params => {
        const onDelete = e => {
          e.stopPropagation()
          setFilterRequest({ uid: params.row.uid, status: "accepted" , handler:"both"})
          modalOpen ? setModalOpen(false) : setModalOpen(true)
        }

        return (
          <div>
            <AiFillDelete
              onClick={onDelete}
              className='icon'
              style={{
                color: "red",
                fontSize: "20px",
                backgroundColor: "white",
                padding: "10px"
              }}
            />
          </div>
        )
      }
    }
  ]

  const fetchSubUsersData = () => {
    dispatch(subUsersData())
      .unwrap()
      .then(res => {
        setUserData(res.subUsers.data.subUsers)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    setLoading(true)
    fetchSubUsersData()
  }, [])

  const handleYesButton = () => {
    dispatch(deleteUser(filterRequest))
      .unwrap()
      .then(res => {
        if (res.users.status === 201) {
          fetchSubUsersData()
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
    setModalOpen(false)
  }

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div style={{ height: "75vh", width: "100%" }}>
        <h1 style={{ display: "flex", justifyContent: "start" , marginBottom:'0.8rem' }}>
            Incomplete User
          </h1>
        {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px"
            }}
          >
            <Button variant='outlined'>
              <ExportToCSV data={userData} />
            </Button>
          </div> */}
          <DataGrid
            rows={userData && userData.length ? userData : []}
            columns={columns}
            pageSize={15}
            rowsPerPageOptions={[15]}
            getRowId={row => row._id}
            components={{ Toolbar: GridToolbar ,Pagination: CustomPagination,}}
            componentsProps={{
              toolbar: {
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              }
            }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
          />
          {modalOpen && (
            <ActionDialogs
              ModalTitle={"Incomplete User table"}
              modalBody='The user will be logged out of the app immediately and he/she will not be able to use the app further. You have to go to delete requests page to fulfill this request'
              handleYesButton={handleYesButton}
              handler={filterRequest?.handler}
              titleShow={true}
            />
          )}
        </div>
      )}
    </Layout>
  )
}
