import React from "react"
import Navbar from "../components/navbar"
import Sidebar from "../components/sidebar"
import { Container, Widgets, Wrapper } from "./index.styles"

export default function Layout({ children }) {
  return (
    <Container>
      <Sidebar>
        <Wrapper>
          {/* <Navbar /> */}
          <Widgets>{children}</Widgets>
        </Wrapper>
      </Sidebar>
    </Container>
  )
}
