import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./slices/auth";
import messageReducer from "./slices/message";
import userReducer from "./slices/dashboard";
const reducer = {
  auth: authReducer,
  message: messageReducer,
  user:userReducer
}
const store = configureStore({
  reducer: reducer,
  devTools: true,
  middleware: getDefaultMiddleware =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),
})
export default store;