import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const dashboardData = createAsyncThunk(
  "admin/dashboard",
  async thunkAPI => {
    try {
      const data = await userService.getDashboardData()
      return { dashboard: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  dashboard: null
}

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: {
    [dashboardData.fulfilled]: (state, action) => {
      state.dashboard = action.payload.dashboard
    },
    [dashboardData.rejected]: (state, action) => {
      state.dashboard = null
    }
  }
})

const { reducer } = dashboardSlice

export default reducer
