import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { login } from "../../redux/slices/auth"
import { clearMessage } from "../../redux/slices/message"
import {
  Button,
  Container,
  Form,
  Input,
  Link,
  Title,
  Wrapper
} from "./login.styles"

const initialValues = {
  email: "",
  password: ""
}

export default function Login() {
  const [loading, setLoading] = useState(false)
  const [formValue, setFormValue] = useState(initialValues)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(clearMessage())
  }, [])

  const handleChange = e => {
    const { name, value } = e.target
    setFormValue({ ...formValue, [name]: value })
  }

  const handleLogin = () => {
    const { username, password } = formValue
    setLoading(true)
    dispatch(login({ email: username, password }))
      .unwrap()
      .then(() => {
        navigate("/")
        window.location.reload()
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <>
      <Container>
        <Wrapper>
          <Title>SIGN IN</Title>
          <Form>
            <Input
              placeholder='email'
              onChange={handleChange}
              name='username'
              value={formValue.username}
            />
            <Input
              placeholder='password'
              onChange={handleChange}
              name='password'
              value={formValue.password}
            />
            <Button type='submit' onClick={handleLogin}>
              LOGIN
            </Button>
            {/* <Link>FORGOT YOUR PASSWORD?</Link> */}
          </Form>
        </Wrapper>
      </Container>
    </>
  )
}
