import styled from "styled-components"

export const Container = styled.div``
export const Wrapper = styled.div`
  flex: 6;
`
export const Widgets = styled.div`
  padding: 20px;
  gap: 20px;
  text-align: center;
`
