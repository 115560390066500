import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import userService from "../../services/user.service"
import { setMessage } from "./message"

export const deletedUserData = createAsyncThunk(
  "admin/deleted-user",
  async thunkAPI => {
    try {
      const data = await userService.getDeleteRequestData()
      return { users: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

export const approveDeleteRequest = createAsyncThunk(
  "admin/update-delete-request",
  async (request, thunkAPI) => {
    try {
      const data = await userService.approveDeleteRequest(request)
      return { user: data }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue()
    }
  }
)

const initialState = {
  users: null,
  approved: true
}

const photosSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: {
    [deletedUserData.fulfilled]: (state, action) => {
      state.users = action.payload.users
    },
    [deletedUserData.rejected]: (state, action) => {
      state.users = null
    },
    [approveDeleteRequest.fulfilled]: (state, action) => {
      state.approved = action.payload.user
    },
    [approveDeleteRequest.rejected]: (state, action) => {
      state.approved = false
    }
  }
})

const { reducer } = photosSlice

export default reducer
