import React from "react"
import { BsFillBarChartFill } from "react-icons/bs"


import { Link } from "react-router-dom"
import {
  Counter,
  Left,
  Links,
  Percentage,
  Right,
  Title,
  Widget,
  CardsWrap,
  CardItems,
  CardInner,
  CardTop,
  Section,
  CardBottom,
  CardBottomDetails,
  PercentageIncrease
} from "./card.styles"


export default function Card({ type,dataForDashboard }) {
  let data;
  

  const diff = 20

  switch (type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        path: "/users",
        amount: dataForDashboard?.NumberOfUsers,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "subUser":
      data = {
        title: "SUBUSERS",
        isMoney: false,
        link: "See all Sub-Users",
        path: "/incomplete-users",
        amount: dataForDashboard?.NumberOfSubUsers,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "about":
      data = {
        title: "ABOUT ME APPROVAL",
        isMoney: false,
        link: "See all users",
        path: "/about-me-approval",
        amount: dataForDashboard?.NumberOfAboutMeVerified,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "photo":
      data = {
        title: "PHOTOS APPROVALS",
        isMoney: false,
        link: "See all users",
        path: "/photo-approval-table",
        amount: dataForDashboard?.NumberOfPhotoApproval,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "photoId":
      data = {
        title: "PHOTOID APPROVALS",
        isMoney: false,
        link: "See all users",
        path: "/photo-id-approval",
        amount: dataForDashboard?.NumberOfPhotoIdArroval,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "contact":
      data = {
        title: "CONTACT REQUESTS",
        isMoney: false,
        link: "See all users",
        path: "/contact-request",
        amount: dataForDashboard?.NumberOfContactUs,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "delete":
      data = {
        title: "DELETE REQUEST",
        isMoney: false,
        link: "See all users",
        path: "/delete-request",
        amount: dataForDashboard?.NumberOfDeletedUser,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    case "verification":
      data = {
        title: "VERIFICATION SUMMARY",
        isMoney: false,
        link: "See all users",
        path: "/verification-summary",
        amount: dataForDashboard?.NumberOfUsers,
        icon: (
          <BsFillBarChartFill
            className='icon'
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)"
            }}
          />
        )
      }
      break
    default:
      break
  }
  return (
    
    <Widget>
      <CardsWrap>
        <CardItems>
          <CardInner>
            <CardTop>
              <Section>
                <Left>
                  <Title>{data.title}</Title>
                  <Counter>
                    {data.isMoney && "$"} {data?.amount}
                  </Counter>
                </Left>
              </Section>
              <Section>
                <Right>
                  <Percentage>{diff} %</Percentage>
                  {data.icon}
                </Right>
              </Section>
            </CardTop>
            <CardBottom>
              <CardBottomDetails>
                <PercentageIncrease>
                  <b>13</b>% increase
                </PercentageIncrease>
                <Section>
                  <Link to={data.path}>
                    <Links>{data.link}</Links>
                  </Link>
                </Section>
              </CardBottomDetails>
            </CardBottom>
          </CardInner>
        </CardItems>
      </CardsWrap>
    </Widget>
  )
}
