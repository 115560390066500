import React from 'react'
import {CSVLink} from "react-csv"

export default function ExportToCSV({data, headers, }) {
  return (
    <CSVLink data={data} headers={headers}>
        Download
    </CSVLink>
  )
}
